define("discourse/plugins/discourse-gradle-issue/gradle-issue/templates/connectors/topic-title/gradle-issue-link", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.gradleBugId}}
    <div class="alert alert-info">
      This discussion is linked to issue
      <a href={{this.gradleBugUrl}}>{{this.gradleBugId}}</a>
    </div>
  {{/if}}
  */
  {
    "id": "2o77O2oG",
    "block": "[[[41,[30,0,[\"gradleBugId\"]],[[[1,\"  \"],[10,0],[14,0,\"alert alert-info\"],[12],[1,\"\\n    This discussion is linked to issue\\n    \"],[10,3],[15,6,[30,0,[\"gradleBugUrl\"]]],[12],[1,[30,0,[\"gradleBugId\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"div\",\"a\"]]",
    "moduleName": "discourse/plugins/discourse-gradle-issue/gradle-issue/templates/connectors/topic-title/gradle-issue-link.hbs",
    "isStrictMode": false
  });
});